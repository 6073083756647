<template>
  <vx-card title="إضافة مجموعة جديدة" title-color="primary">
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-2">
        <label class="vs-input--label">وصف المجموعة</label>
        <vs-textarea
          counter="100"
          name="description"
          v-model="collection.description"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('description')">
          {{ errors.first("description") }}
        </span>
      </div>
    </div>
    <vs-divider position="left" textClass="text-size" color="primary">
      إختيار المنتجات
    </vs-divider>
    <div class="vx-row">
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
        <label class="text-sm opacity-75">فلترة حسب الجنس</label>
        <v-select
          :options="filters"
          :clearable="false"
          v-model="gender_filter"
          @option:selected="clearFields"
          label="name"
          class="mb-4 sm:mb-0"
        />
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full" v-if="filters.length">
        <label class="text-sm opacity-75">فلترة حسب التصنيف الرئيسي </label>
        <v-select
          :options="gender_filter.categories"
          :clearable="false"
          label="name"
          v-model="category_filter"
        />
      </div>
      <div class="vx-col md:w-1/4 sm:w-1/2 w-full" v-if="filters.length">
        <label class="text-sm opacity-75">التصنيف الفرعي</label>
        <v-select
          :options="category_filter.sub_categories"
          :clearable="false"
          label="name"
          v-model="subCategory_filter"
        />
      </div>
      <div class="vx-col w-1/4 sm:w-1/4 w-full flex">
        <vs-button
          class="mt-5 pt-1 pb-1"
          size="medium"
          color="success"
          type="filled"
          @click="FilterProducts"
          >فلتر
        </vs-button>
        <vs-button
          class="mt-5 ml-2"
          color="warning"
          type="filled"
          icon-pack="feather"
          icon="icon-rotate-ccw"
          @click="fetchProducts(`page=${current_Page}`)"
        ></vs-button>
      </div>
    </div>
    <div class="vx-row">
      <div
        class="vx-col w-full sm:w-1/4 lg:w-1/5 mb-base"
        v-for="product in products"
        :key="product.id"
      >
        <vs-checkbox
          class="mt-5 pr-10 text-lg mb-10 mt-10"
          :vs-value="product.id"
          v-model="collection.products"
        >
        </vs-checkbox>
        <vx-card>
          <div slot="no-body">
            <img
              :src="product.first_media.path"
              class="responsive card-img-top"
            />
          </div>
          <h5 class="mb-2">{{ product.title }}</h5>
        </vx-card>
      </div>
      <vs-pagination
        class="mt-4"
        :total="lastPage"
        v-model="current_Page"
        @change="fetchProducts(`page=${current_Page}`)"
      ></vs-pagination>
    </div>
    <div class="vx-row mt-8 flex justify-end">
      <div class="vx-col sm:w-1/2 w-full mb-6 flex justify-end">
        <vs-button
          class="mr-5 py-4"
          @click="$router.go(-1)"
          color="warning"
          type="border"
          >إلغاء</vs-button
        >
        <vs-button
          class="mr-5 py-4"
          @click="submit"
          color="primary"
          :disabled="this.collection.products.length < 2"
          type="filled"
          >إضافة مجموعة</vs-button
        >
      </div>
    </div>
  </vx-card>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
export default {
  components: { vSelect },
  data() {
    return {
      current_page: "",
      gender_filter: {},
      category_filter: {},
      subCategory_filter: {},
      collection: {
        description: "",
        products: [],
      },
    };
  },
  computed: {
    lastPage() {
      return this.$store.state.products.pagination;
    },
    products() {
      return this.$store.state.products.products;
    },
    filters() {
      return this.$store.state.products.options;
    },
  },
  methods: {
    ...mapActions("products", ["fetchProductOptions", "fetchProducts",'fetchFilteredProducts']),
    ...mapActions("collections", ["createNewCollection"]),
    clearFields() {
      (this.category_filter = {}), (this.subCategory_filter = {});
    },
    FilterProducts() {
      let bodyFormdata = new FormData();
      if(Object.keys(this.category_filter))
      bodyFormdata.set("category_id", this.category_filter.id);
      if(Object.keys(this.gender_filter))
      bodyFormdata.set("gender_id", this.gender_filter.id);
      this.fetchFilteredProducts(bodyFormdata)
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          var bodyFormData = new FormData();
          bodyFormData.set("description", this.collection.description);
          for (var i = 0; i < this.collection.products.length; i++) {
            bodyFormData.set(
              `products[${i}]`,
              this.collection.products[i]
            );
          }

          this.createNewCollection(bodyFormData)
            .then((response) => {
              this.successDialog(response.data.message);
              this.$router.push({ name: "collections" });
            })
            .catch((error) => {
              this.errorDialog(error);
            });
        }
      });
    },
  },
  created() {
    this.fetchProducts(`page=${this.current_Page}`);
    this.fetchProductOptions();
  },
};
</script>

<style>
</style>
